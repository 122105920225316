import { default as _91id_93xQsfV93luuMeta } from "/app/admin/pages/author/[id].vue?macro=true";
import { default as indexJ7oJMIDataMeta } from "/app/admin/pages/author/index.vue?macro=true";
import { default as _91id_93FAxVvXPkMpMeta } from "/app/admin/pages/broker-post/[id].vue?macro=true";
import { default as indexsqrXCAOoCBMeta } from "/app/admin/pages/broker-post/index.vue?macro=true";
import { default as _91id_93oLfrNI3ElpMeta } from "/app/admin/pages/broker/[id].vue?macro=true";
import { default as indexBfJTAN8L4UMeta } from "/app/admin/pages/broker/index.vue?macro=true";
import { default as _91id_93INq5t7Jfp9Meta } from "/app/admin/pages/category/[id].vue?macro=true";
import { default as indexTd3mYkMozfMeta } from "/app/admin/pages/category/index.vue?macro=true";
import { default as _91id_93m7FP9iHW53Meta } from "/app/admin/pages/comment/[id].vue?macro=true";
import { default as indexzbxxmo0FwvMeta } from "/app/admin/pages/comment/index.vue?macro=true";
import { default as _91id_93CvORyasnKnMeta } from "/app/admin/pages/comparison/[id].vue?macro=true";
import { default as indexyE0CTpgKI0Meta } from "/app/admin/pages/comparison/index.vue?macro=true";
import { default as _91id_93QQ6lqWCMj2Meta } from "/app/admin/pages/contact-us/[id].vue?macro=true";
import { default as index7BSFz193tRMeta } from "/app/admin/pages/contact-us/index.vue?macro=true";
import { default as _91id_93AVKoXbs60CMeta } from "/app/admin/pages/country/[id].vue?macro=true";
import { default as indexjSkR4XqTQbMeta } from "/app/admin/pages/country/index.vue?macro=true";
import { default as indexascqIqUEvqMeta } from "/app/admin/pages/duplication-tool/index.vue?macro=true";
import { default as _91id_93TGjblIdocSMeta } from "/app/admin/pages/education/[id].vue?macro=true";
import { default as indexdZWBtuY2fQMeta } from "/app/admin/pages/education/index.vue?macro=true";
import { default as _91id_93Fr7WzvejEuMeta } from "/app/admin/pages/guide/[id].vue?macro=true";
import { default as indexEY9HXPkLwdMeta } from "/app/admin/pages/guide/index.vue?macro=true";
import { default as indexh15qlbWlWCMeta } from "/app/admin/pages/index.vue?macro=true";
import { default as _91id_935a67T86RhwMeta } from "/app/admin/pages/language/[id].vue?macro=true";
import { default as index52PzyniX8uMeta } from "/app/admin/pages/language/index.vue?macro=true";
import { default as indexm7LdfSihLcMeta } from "/app/admin/pages/login/index.vue?macro=true";
import { default as indexPkI3t5hie2Meta } from "/app/admin/pages/logout/index.vue?macro=true";
import { default as _91id_93K8Esu3KKh4Meta } from "/app/admin/pages/payment-method/[id].vue?macro=true";
import { default as index1hgicb5V9GMeta } from "/app/admin/pages/payment-method/index.vue?macro=true";
import { default as _91id_939U7sun2bRzMeta } from "/app/admin/pages/post/[id].vue?macro=true";
import { default as index3owntXIcqdMeta } from "/app/admin/pages/post/index.vue?macro=true";
import { default as indexF22tmq67wCMeta } from "/app/admin/pages/profile/index.vue?macro=true";
import { default as _91id_93GC7Hv64q2ZMeta } from "/app/admin/pages/redirect/[id].vue?macro=true";
import { default as index94GT2Rhm67Meta } from "/app/admin/pages/redirect/index.vue?macro=true";
import { default as _91id_93KaDlk9G75iMeta } from "/app/admin/pages/websites/[id].vue?macro=true";
import { default as indexV6UuDz6ySwMeta } from "/app/admin/pages/websites/index.vue?macro=true";
export default [
  {
    name: "author-id",
    path: "/author/:id()",
    meta: _91id_93xQsfV93luuMeta || {},
    component: () => import("/app/admin/pages/author/[id].vue").then(m => m.default || m)
  },
  {
    name: "author",
    path: "/author",
    meta: indexJ7oJMIDataMeta || {},
    component: () => import("/app/admin/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: "broker-post-id",
    path: "/broker-post/:id()",
    meta: _91id_93FAxVvXPkMpMeta || {},
    component: () => import("/app/admin/pages/broker-post/[id].vue").then(m => m.default || m)
  },
  {
    name: "broker-post",
    path: "/broker-post",
    meta: indexsqrXCAOoCBMeta || {},
    component: () => import("/app/admin/pages/broker-post/index.vue").then(m => m.default || m)
  },
  {
    name: "broker-id",
    path: "/broker/:id()",
    meta: _91id_93oLfrNI3ElpMeta || {},
    component: () => import("/app/admin/pages/broker/[id].vue").then(m => m.default || m)
  },
  {
    name: "broker",
    path: "/broker",
    meta: indexBfJTAN8L4UMeta || {},
    component: () => import("/app/admin/pages/broker/index.vue").then(m => m.default || m)
  },
  {
    name: "category-id",
    path: "/category/:id()",
    meta: _91id_93INq5t7Jfp9Meta || {},
    component: () => import("/app/admin/pages/category/[id].vue").then(m => m.default || m)
  },
  {
    name: "category",
    path: "/category",
    meta: indexTd3mYkMozfMeta || {},
    component: () => import("/app/admin/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: "comment-id",
    path: "/comment/:id()",
    meta: _91id_93m7FP9iHW53Meta || {},
    component: () => import("/app/admin/pages/comment/[id].vue").then(m => m.default || m)
  },
  {
    name: "comment",
    path: "/comment",
    meta: indexzbxxmo0FwvMeta || {},
    component: () => import("/app/admin/pages/comment/index.vue").then(m => m.default || m)
  },
  {
    name: "comparison-id",
    path: "/comparison/:id()",
    meta: _91id_93CvORyasnKnMeta || {},
    component: () => import("/app/admin/pages/comparison/[id].vue").then(m => m.default || m)
  },
  {
    name: "comparison",
    path: "/comparison",
    meta: indexyE0CTpgKI0Meta || {},
    component: () => import("/app/admin/pages/comparison/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-id",
    path: "/contact-us/:id()",
    meta: _91id_93QQ6lqWCMj2Meta || {},
    component: () => import("/app/admin/pages/contact-us/[id].vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    meta: index7BSFz193tRMeta || {},
    component: () => import("/app/admin/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "country-id",
    path: "/country/:id()",
    meta: _91id_93AVKoXbs60CMeta || {},
    component: () => import("/app/admin/pages/country/[id].vue").then(m => m.default || m)
  },
  {
    name: "country",
    path: "/country",
    meta: indexjSkR4XqTQbMeta || {},
    component: () => import("/app/admin/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "duplication-tool",
    path: "/duplication-tool",
    meta: indexascqIqUEvqMeta || {},
    component: () => import("/app/admin/pages/duplication-tool/index.vue").then(m => m.default || m)
  },
  {
    name: "education-id",
    path: "/education/:id()",
    meta: _91id_93TGjblIdocSMeta || {},
    component: () => import("/app/admin/pages/education/[id].vue").then(m => m.default || m)
  },
  {
    name: "education",
    path: "/education",
    meta: indexdZWBtuY2fQMeta || {},
    component: () => import("/app/admin/pages/education/index.vue").then(m => m.default || m)
  },
  {
    name: "guide-id",
    path: "/guide/:id()",
    meta: _91id_93Fr7WzvejEuMeta || {},
    component: () => import("/app/admin/pages/guide/[id].vue").then(m => m.default || m)
  },
  {
    name: "guide",
    path: "/guide",
    meta: indexEY9HXPkLwdMeta || {},
    component: () => import("/app/admin/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexh15qlbWlWCMeta || {},
    component: () => import("/app/admin/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "language-id",
    path: "/language/:id()",
    meta: _91id_935a67T86RhwMeta || {},
    component: () => import("/app/admin/pages/language/[id].vue").then(m => m.default || m)
  },
  {
    name: "language",
    path: "/language",
    meta: index52PzyniX8uMeta || {},
    component: () => import("/app/admin/pages/language/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexm7LdfSihLcMeta || {},
    component: () => import("/app/admin/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    meta: indexPkI3t5hie2Meta || {},
    component: () => import("/app/admin/pages/logout/index.vue").then(m => m.default || m)
  },
  {
    name: "payment-method-id",
    path: "/payment-method/:id()",
    meta: _91id_93K8Esu3KKh4Meta || {},
    component: () => import("/app/admin/pages/payment-method/[id].vue").then(m => m.default || m)
  },
  {
    name: "payment-method",
    path: "/payment-method",
    meta: index1hgicb5V9GMeta || {},
    component: () => import("/app/admin/pages/payment-method/index.vue").then(m => m.default || m)
  },
  {
    name: "post-id",
    path: "/post/:id()",
    meta: _91id_939U7sun2bRzMeta || {},
    component: () => import("/app/admin/pages/post/[id].vue").then(m => m.default || m)
  },
  {
    name: "post",
    path: "/post",
    meta: index3owntXIcqdMeta || {},
    component: () => import("/app/admin/pages/post/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexF22tmq67wCMeta || {},
    component: () => import("/app/admin/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "redirect-id",
    path: "/redirect/:id()",
    meta: _91id_93GC7Hv64q2ZMeta || {},
    component: () => import("/app/admin/pages/redirect/[id].vue").then(m => m.default || m)
  },
  {
    name: "redirect",
    path: "/redirect",
    meta: index94GT2Rhm67Meta || {},
    component: () => import("/app/admin/pages/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: "websites-id",
    path: "/websites/:id()",
    meta: _91id_93KaDlk9G75iMeta || {},
    component: () => import("/app/admin/pages/websites/[id].vue").then(m => m.default || m)
  },
  {
    name: "websites",
    path: "/websites",
    meta: indexV6UuDz6ySwMeta || {},
    component: () => import("/app/admin/pages/websites/index.vue").then(m => m.default || m)
  }
]