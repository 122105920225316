// plugins/sweetalert.js
import Swal from 'sweetalert2';

export default defineNuxtPlugin(() => {

    const options = {
        icon: 'info',
        timer: 8000,
        timerProgressBar: true,
        width: 400,
        heightAuto: false,
        padding: '2.5rem',
        buttonsStyling: false,
        confirmButtonColor: null,
        cancelButtonColor: null ,
        confirmButtonClass: 'btn mx-1 fw-bold btn-light-primary',
        cancelButtonClass: 'btn mx-1 fw-bold btn-light-warning',
        confirmButtonText : "Ok, got it!",
        reverseButtons : true ,
        focusConfirm: true  ,
    }

  return {
    provide: {
      swal: Swal, // Swal.mixin(options)
    }
  }
}); 